/* TODO: Consider removing or deleting styles related to colors */
/* TODO: Theme colors */
.color.yellow {
  color: #e27c00;
}

.color.red,
.color.red:hover {
  color: #e74c3c;
}

.color.gray,
.color.gray:hover {
  color: #b3b3b3;
}

.display.inline-block {
  display: inline-block;
}

/* TODO: Look into removing */
div.display.inline-block {
  display: inline-block;
}

.display.none {
  display: none;
}

.margin-right.half-em {
  margin-right: 0.5em;
}

.margin-left.half-em {
  margin-left: 0.5em;
}

@media screen and (max-width: 767px) {
  .cc-hide-on-mobile {
    display: none !important;
  }
}
@media screen and (max-width: 991px) {
  .cc-hide-on-medium {
    display: none !important;
  }
}

@media screen and (min-width: 768px) {
  .cc-mobile-only {
    display: none !important;
  }
}

@media print {
  .cc-hide-on-print {
    display: none !important;
  }
}

.cc-visibility-hidden {
  visibility: hidden;
}

.clickable {
  cursor: pointer;
}

.clickable:hover {
  text-decoration: underline;
}

.hover-highlight:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

.semi-bold {
  font-weight: 600;
}

.open-sans-14px {
  font-family: 'Open Sans';
  font-size: 14px;
}

.open-sans-12px {
  font-family: 'Open Sans';
  font-size: 12px;
}

/* TODO: Examine if this is even applied anywhere, and remove */
i.vertical-align.text-top {
  vertical-align: text-top;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.force-wrap {
  overflow-wrap: break-word;
  width: 100%;
}

/* Use with a button element to remove button styles */
.cc-button-reset {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  /* outline: inherit; */
}

/* Moving this after .cc-button-reset to ensure both can be used together without collisions */
a,
.cc-link {
  cursor: pointer;
  color: #3275af;
  text-decoration: underline;
}

a:hover,
.cc-link:hover {
  color: #23527c;
  text-decoration: underline;
}

.cc-focus:focus {
  outline: auto 5px #2e63a5;
  outline: auto 5px -webkit-focus-ring-color;
}

.cc-screen-reader-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.cc-loading span {
  animation: blink 1s infinite both;
}
.cc-loading span:nth-child(2) {
  animation-delay: 0.2s;
}
.cc-loading span:nth-child(3) {
  animation-delay: 0.4s;
}
@keyframes blink {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
