body {
  font-family: 'Montserrat', 'Open Sans', sans-serif;
  font-size: 16px;
}

html,
body,
#root,
#app,
.container {
  height: 100%;
}

#app-content {
  padding-top: 51px;
  min-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 2em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  color: #4a4a4a;
  line-height: 1.5;
}

h1 {
  font-size: 34px;
}

h4 {
  font-size: 22px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 14px;
}

p {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 1.38;
  color: #4a4a4a;
}

label {
  font-weight: 300;
  font-family: 'Nunito Sans', sans-serif;
}

.modal-60w {
  width: 60%;
}

@keyframes copyEffect {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.notification-message {
  animation: copyEffect 2s ease-in-out;
  animation-fill-mode: forwards;
}

.footer-navigation-error-button:hover {
  background-color: #e0e0e0;
}

.clamp-overflow-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.clamp-overflow-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.clamp-overflow-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.flex-center-row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.flex-space-between-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
