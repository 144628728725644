/* TODO: Examine these classes and see if they really need to be a
global classes, or if they can just be applied to a component */

h6.list-group-item-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 10em;
}

h6.list-group-item-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 10em;
}

li.list-group-item.cc-panel-item {
  border: 1px solid #dadada;
  border-left: 0;
  border-right: 0;
  margin-bottom: 0;
}
li.list-group-item.cc-panel-item:not(:last-child) {
  border-bottom-width: 0;
}

div.panel-heading + li.list-group-item.cc-panel-item {
  border-top: 0;
}

li.list-group-item.cc-panel-item:last-child {
  margin-bottom: -1px;
}

.panel {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
}

/* Used with react-select dropdown */
.cc-select-option-text {
  color: #4a4a4a;
  font-family: 'Open Sans';
  font-size: 16px;
}

/* React Select Styles */
.custom-select__control--is-disabled {
  background-color: #eee;
}

.custom-select__value-container {
  padding-right: 18px;
}

.custom-select__clear-indicator {
  font-size: 24px;
  vertical-align: middle;
}

.custom-select__control--is-focused .custom-select__single-value,
.custom-select__control--is-focused .custom-select__placeholder {
  display: none;
}

.custom-select__placeholder {
  color: #737373;
}

.custom-select__menu {
  z-index: 1000 !important;
  position: relative;
}

a.freshwidget-theme {
  display: none;
}

@media (min-width: 414px) {
  h6.list-group-item-text {
    max-width: 14em;
  }
}

@media (min-width: 768px) {
  a.freshwidget-theme {
    display: block;
  }
  h6.list-group-item-text {
    max-width: 23em;
  }
}

/*
This is to manage the dropdown appearance within mediae
*/
.cc-course-panel > .media-body,
.cc-course-panel.media {
  overflow: visible; /*overflow was set to hidden before */
}

.cc-course-panel > .media-body:after,
.cc-course-panel.media:after {
  clear: both;
  content: ' ';
  display: block;
  height: 0;
}

.cc-lms-frontend_foundations-greenanchor:hover {
  color: green;
}
.cc-content-answer:hover .cc-content-answer-hidden {
  display: block;
}
.cc-content-answer:focus .cc-content-answer-hidden {
  display: block;
}
.cc-content-answer-hidden {
  display: none;
}
